var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a", { on: { click: _vm.handleConnect } }, [
    _c("img", { attrs: { src: "/assets/images/phantom_small.png" } }),
    _vm._v(" "),
    !_vm.loading
      ? _c(
          "span",
          [
            _vm._t("default"),
            _vm._v(
              " " + _vm._s(_vm.connected ? _vm.shortAddr : _vm.wallet_addr)
            ),
          ],
          2
        )
      : _c("div", { staticClass: "d-inline-block" }, [
          _c("i", { staticClass: "fa fa-spin fa-spinner" }),
          _vm._v(" Loading"),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }