<template>
<div class="report-bug">
	<span class="bug-btn" v-on:click="toggleBugForm"><i class="fa fa-bug"></i> Report Bug</span>
	<div class="bug-form" v-if="showForm">
		<div class="form-container text-start">
			<h4>Report Bug</h4>
			<form @submit="send">
				<div class="mb-3">
					<label>User</label>
					<input v-model="form.user" class="form-control" placeholder="User" readonly>
				</div>
				<div class="mb-3">
					<label>Title</label>
					<input v-model="form.title" class="form-control" placeholder="Title">
				</div>
				<div class="mb-3">
					<label>Description</label>
					<textarea class="form-control" placeholder="Please provide as much information as possible!" v-model="form.description"></textarea>
				</div>

				<button @submit="send" :disabled="!$store.state.wallet.connected" class="btn btn-primary btn-block">Submit Report</button>
			</form>
		</div>
	</div>
</div>
</template>

<script>
export default {
	name: "ReportBug",
	data() {
		return {
			showForm: false,
			form: {
				user: '',
				title: '',
				description: '',
			}
		}
	},
	watch: {
		'$store.state.wallet.address'() {
			this.form.user = this.$store.state.wallet.address
		}
	},
	methods:{
		toggleBugForm: function() {
			this.showForm = !this.showForm
		},
		send: function(e) {
			e.preventDefault();
			//TODO send form

			this.form = {
				user: this.$store.state.wallet.address,
				title: '',
				description: '',
			}
			this.$toastr.s("Bug report sent!")
			this.toggleBugForm();
		}
	},
	mounted() {
		this.form.user = this.$store.state.wallet.address
	}
}
</script>

<style scoped>
.report-bug {
	position: relative;
}
.bug-form {
	position: absolute;
	top: 150%;
	left: -150px;
	background: rgba(0, 62, 121, 0.8);
	border-radius: 7px;
	z-index: 99;
	width: 300px;
	border: 2px solid white;
}

.form-container {
	padding: 5%;
}

sup {
	font-size: 0.6em;
}

.form-control {
	background: transparent;
	color: white;
	font-size: 0.8em;
}

::placeholder {
	color: white;
}

.bug-btn {
	cursor: pointer;
	transition: all 0.3s ease-out;
	border-bottom: 2px solid transparent;
}

.bug-btn:hover {
	text-shadow: 0 0 4px white;
	padding-bottom: 5%;
	border-bottom: 2px solid white;
}

</style>
