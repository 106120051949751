var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "report-bug" }, [
    _c("span", { staticClass: "bug-btn", on: { click: _vm.toggleBugForm } }, [
      _c("i", { staticClass: "fa fa-bug" }),
      _vm._v(" Report Bug"),
    ]),
    _vm._v(" "),
    _vm.showForm
      ? _c("div", { staticClass: "bug-form" }, [
          _c("div", { staticClass: "form-container text-start" }, [
            _c("h4", [_vm._v("Report Bug")]),
            _vm._v(" "),
            _c("form", { on: { submit: _vm.send } }, [
              _c("div", { staticClass: "mb-3" }, [
                _c("label", [_vm._v("User")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.user,
                      expression: "form.user",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { placeholder: "User", readonly: "" },
                  domProps: { value: _vm.form.user },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "user", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-3" }, [
                _c("label", [_vm._v("Title")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.title,
                      expression: "form.title",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { placeholder: "Title" },
                  domProps: { value: _vm.form.title },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "title", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-3" }, [
                _c("label", [_vm._v("Description")]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.description,
                      expression: "form.description",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: {
                    placeholder:
                      "Please provide as much information as possible!",
                  },
                  domProps: { value: _vm.form.description },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form, "description", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary btn-block",
                  attrs: { disabled: !_vm.$store.state.wallet.connected },
                  on: { submit: _vm.send },
                },
                [_vm._v("Submit Report")]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }